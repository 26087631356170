import React from "react";
import SEO from "../components/SEO";
import ImpressumLayout from "../components/layouts/ImpressumLayout";

const ImpressumPage = () => {
  return (
    <ImpressumLayout seoComponent={<SEO title="Imprint" />} title="Imprint">
      <h2>Responsible for the content</h2>
      <p>
        BRUGG Lifting AG
        <br />
        Wydenstrasse 36
        <br />
        CH-5242 Birr
        <br />
        <a href="tel:+41564644242">+41 56 464 42 42</a>
        <br />
        <a href="mailto:info.lifting@brugg.com">info.lifting@brugg.com</a>
      </p>

      <h2>Web and mobile app development</h2>
      <p>
        Florian Gyger Software
        <br />
        Bachmattstrasse 15
        <br />
        CH-5210 Windisch
        <br />
        <a href="https://floriangyger.ch" target="_blank" rel="noopener">
          www.floriangyger.ch
        </a>
      </p>

      <h2>Legal instructions</h2>

      <h3>Disclaimer</h3>
      <p>
        The contents of this website only serve for information purposes and may
        be amended at any time without prior notice. BRUGG does not warrant that
        the information published on this website is correct, complete and
        up-to-date. BRUGG disclaims any liability for losses or damages of any
        kind resulting from the use of or access to this website.
      </p>

      <h3>No offer</h3>
      <p>
        The information published on this website does not represent either a
        recommendation or an offer or a solicitation to conclude a transaction.
      </p>

      <h3>Linked websites</h3>
      <p>
        BRUGG accepts no responsibility for the correctness, completeness and
        legality of the contents of third party websites that can be accessed
        via this website.
      </p>

      <h3>Use of this Website</h3>
      <p>
        All contents of this website are copyrighted. All copyrights are owned
        by BRUGG, unless otherwise stated. This website must not be used for
        purposes other than personal purposes without the prior written consent
        of BRUGG.
      </p>

      <h3>Local legal restrictions</h3>
      <p>
        This website is not designed for persons who are subject to a
        jurisdiction in which the publication of or the access to this website
        would violate applicable law (on account of the nationality of the
        person concerned, his/her residence, or for other reasons). Persons to
        whom such restrictions apply are not permitted to access this website.
      </p>

      <h3>Cookies</h3>
      <p>
        Some of our website pages use so-called cookies. Cookies do not cause
        any damage on your computer and do not contain viruses. Cookies are used
        to make our online presence more user-friendly, effective and secure.
        Cookies are small text files that are filed on your computer and stored
        by your browser. Most of the cookies used by us are so-called
        “session-cookies”, which are deleted automatically after the end of your
        session. Other cookies remain stored on your device, until you delete
        them. The cookies enable us to recognize your browser at your next visit
        of our website. You may set your browser to automatically inform you
        whenever cookies are set and to allow cookies to be set in individual
        cases, to exclude the acceptance of cookies in certain cases or
        generally, and to activate the automatic deletion of cookies whenever
        the browser is closed. If you disable cookies, the functionality of this
        website may be restricted.
      </p>

      <h3>Copyright</h3>
      <p>
        The contents of this website may be used for any Purposes only with the
        prior written consent of Brugg Lifting AG, CH-5200 Brugg.
      </p>
    </ImpressumLayout>
  );
};

export default ImpressumPage;
